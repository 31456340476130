<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      width="630"
      overlay-color="overlay"
    >
      <v-card class="text-center">
        <v-toolbar class="px-4" color="transparent" flat height="48">
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" class="pt-7 mr-4" justify="center">
              <v-btn @click="dialog=false" icon small><v-icon size="20" color="fontBody">mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="pb-7">
          <v-img contain :height="190" :src="require(`@/assets/alert-dialog/ax.svg`)" />
          <span class="d-block subtitle-1 mt-4 font-weight-bold primary--text">SII INFORMA</span>
          <v-col class="text-left pb-0">
            Les informamos que, a partir del <span class="font-weight-semibold">martes 3 de septiembre de 2024, desde las 23:00 horas y por un lapso de 4 horas,</span> el SII realizará un mantenimiento programado, por lo que sus servicios no estarán disponibles.
            <span class="mt-3 d-block">Una vez que el SII haya restablecido los servicios, las acciones de reprocesamiento automático de Axteroid se encargarán de regularizar todos los documentos pendientes.</span>
            <span class="d-block mt-4">
              Para más información: <a class="text-decoration-none" href="https://homer.sii.cl/" target="_blank">Consulta aquí</a>
            </span>
            <span class="mt-3 d-block">Atentamente, <span class="d-block">Equipo Axteroid.</span></span>
          </v-col>
        </v-card-text>
        <!-- <v-card-text class="pb-7">
          <v-img contain :height="190" :src="require(`@/assets/alert-dialog/ax.svg`)" />
          <span class="d-block subtitle-1 mt-4 font-weight-bold primary--text">SUNAT INFORMA</span>
          <v-col class="text-left pb-0">
            Cumplimos con notificar que desde las <span class="font-weight-semibold">22:00 horas del miércoles 14 de agosto</span> hasta las <span class="font-weight-semibold">04:00 horas del jueves 15 de agosto</span>, SUNAT presentará intermitencia en los servicios de emisión y recepción de comprobantes electrónicos.
            <span class="mt-3 d-block">Procesos que se verán afectados por estas intermitencias:</span>
            <ul class="pl-6 mt-3">
              <li>Envío de guías de remisión a SUNAT.</li>
              <li>Intermitencia en la recepción por SUNAT de los comprobantes emitidos.</li>
              <li>Validación de comprobantes recibidos; es decir, todo lo que envían los proveedores.</li>
            </ul>
            <span class="d-block mt-3">Una vez que SUNAT  haya restablecido los servicios, las acciones de reprocesamiento automáticas de Axteroid se encargarán de regularizar todo documento pendiente.</span>

            <span class="d-block mt-4">
              Para más información: <a class="text-decoration-none" href="https://www.sunat.gob.pe/mensajes/agosto/2024/aviso-ti-140824.html" target="_blank">Consulta aquí</a>
            </span>
            <span class="mt-3 d-block">Atentamente, <span class="d-block">Equipo Axteroid.</span></span>
          </v-col>
        </v-card-text> -->
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  export default {
    data () {
    return {
      dialog: false
    }
  },
  created () {
    this.dialog = true
  }
}
</script>